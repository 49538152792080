(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("antlrCalc", [], factory);
	else if(typeof exports === 'object')
		exports["antlrCalc"] = factory();
	else
		root["antlrCalc"] = factory();
})(window, function() {
return 