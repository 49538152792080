// Generated from ./src/Calculator.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class CalculatorLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly NUMBER = 15;
	public static readonly FLOAT = 16;
	public static readonly DIGIT = 17;
	public static readonly MOD = 18;
	public static readonly WHOLE = 19;
	public static readonly MUL = 20;
	public static readonly DIV = 21;
	public static readonly ADD = 22;
	public static readonly SUB = 23;
	public static readonly PI = 24;
	public static readonly EXPONENT = 25;
	public static readonly NEGEXPONENT = 26;
	public static readonly EULER = 27;
	public static readonly SQRT = 28;
	public static readonly SQR = 29;
	public static readonly FLOOR = 30;
	public static readonly CEIL = 31;
	public static readonly ABS = 32;
	public static readonly ROUNDK = 33;
	public static readonly ROUND = 34;
	public static readonly TRUNC = 35;
	public static readonly SIN = 36;
	public static readonly COS = 37;
	public static readonly TAN = 38;
	public static readonly COT = 39;
	public static readonly SINH = 40;
	public static readonly COSH = 41;
	public static readonly TANH = 42;
	public static readonly ARCSIN = 43;
	public static readonly ARCCOS = 44;
	public static readonly ARCTAN = 45;
	public static readonly ARCTAN2 = 46;
	public static readonly ARCCOT = 47;
	public static readonly EXP = 48;
	public static readonly LN = 49;
	public static readonly EEX = 50;
	public static readonly LOG = 51;
	public static readonly RAD = 52;
	public static readonly DEG = 53;
	public static readonly MIN = 54;
	public static readonly MAX = 55;
	public static readonly WS = 56;
	public static readonly COM = 57;
	public static readonly SUBSTITUTION = 58;
	public static readonly SEMICOLON = 59;
	public static readonly INVALID = 60;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", "T__7", "T__8", 
		"T__9", "T__10", "T__11", "T__12", "T__13", "NUMBER", "FLOAT", "DIGIT", 
		"MOD", "WHOLE", "MUL", "DIV", "ADD", "SUB", "PI", "EXPONENT", "NEGEXPONENT", 
		"EULER", "SQRT", "SQR", "FLOOR", "CEIL", "ABS", "ROUNDK", "ROUND", "TRUNC", 
		"SIN", "COS", "TAN", "COT", "SINH", "COSH", "TANH", "ARCSIN", "ARCCOS", 
		"ARCTAN", "ARCTAN2", "ARCCOT", "EXP", "LN", "EEX", "LOG", "RAD", "DEG", 
		"MIN", "MAX", "WS", "COM", "SUBSTITUTION", "SEMICOLON", "INVALID", "COMMENT",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'='", "'('", "')'", "'^'", "'**'", "'%'", "'~'", "'//'", "'['", 
		"']'", "'{'", "'}'", "'()'", "'..'", undefined, undefined, undefined, 
		undefined, undefined, "'*'", "'/'", "'+'", "'-'", undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, "';'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, "NUMBER", "FLOAT", "DIGIT", "MOD", "WHOLE", "MUL", "DIV", "ADD", 
		"SUB", "PI", "EXPONENT", "NEGEXPONENT", "EULER", "SQRT", "SQR", "FLOOR", 
		"CEIL", "ABS", "ROUNDK", "ROUND", "TRUNC", "SIN", "COS", "TAN", "COT", 
		"SINH", "COSH", "TANH", "ARCSIN", "ARCCOS", "ARCTAN", "ARCTAN2", "ARCCOT", 
		"EXP", "LN", "EEX", "LOG", "RAD", "DEG", "MIN", "MAX", "WS", "COM", "SUBSTITUTION", 
		"SEMICOLON", "INVALID",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(CalculatorLexer._LITERAL_NAMES, CalculatorLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return CalculatorLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(CalculatorLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "Calculator.g4"; }

	// @Override
	public get ruleNames(): string[] { return CalculatorLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return CalculatorLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return CalculatorLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return CalculatorLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02>\u0192\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		"\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t" +
		"\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x04(\t(\x04)\t)\x04*\t*\x04" +
		"+\t+\x04,\t,\x04-\t-\x04.\t.\x04/\t/\x040\t0\x041\t1\x042\t2\x043\t3\x04" +
		"4\t4\x045\t5\x046\t6\x047\t7\x048\t8\x049\t9\x04:\t:\x04;\t;\x04<\t<\x04" +
		"=\t=\x04>\t>\x03\x02\x03\x02\x03\x03\x03\x03\x03\x04\x03\x04\x03\x05\x03" +
		"\x05\x03\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03\b\x03\b\x03\t\x03\t\x03" +
		"\t\x03\n\x03\n\x03\v\x03\v\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x10\x03\x10\x06\x10\xA0\n\x10\r\x10\x0E" +
		"\x10\xA1\x05\x10\xA4\n\x10\x03\x11\x06\x11\xA7\n\x11\r\x11\x0E\x11\xA8" +
		"\x03\x11\x03\x11\x07\x11\xAD\n\x11\f\x11\x0E\x11\xB0\v\x11\x03\x11\x03" +
		"\x11\x06\x11\xB4\n\x11\r\x11\x0E\x11\xB5\x05\x11\xB8\n\x11\x03\x12\x03" +
		"\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03" +
		"\x15\x03\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03\x18\x03\x18\x03\x19\x03" +
		"\x19\x03\x19\x03\x1A\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1B\x03\x1C\x03" +
		"\x1C\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03\x1E\x03" +
		"\x1E\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03 \x03 \x03 \x03" +
		" \x03 \x03!\x03!\x03!\x03!\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03" +
		"#\x03#\x03#\x03#\x03#\x03#\x03$\x03$\x03$\x03$\x03$\x03$\x03%\x03%\x03" +
		"%\x03%\x03&\x03&\x03&\x03&\x03\'\x03\'\x03\'\x03\'\x03(\x03(\x03(\x03" +
		"(\x03)\x03)\x03)\x03)\x03)\x03*\x03*\x03*\x03*\x03*\x03+\x03+\x03+\x03" +
		"+\x03+\x03,\x03,\x03,\x03,\x03,\x03,\x03,\x03-\x03-\x03-\x03-\x03-\x03" +
		"-\x03-\x03.\x03.\x03.\x03.\x03.\x03.\x03.\x03/\x03/\x03/\x03/\x03/\x03" +
		"/\x03/\x03/\x030\x030\x030\x030\x030\x030\x030\x031\x031\x031\x031\x03" +
		"2\x032\x032\x033\x033\x033\x033\x034\x034\x034\x034\x035\x035\x035\x03" +
		"5\x036\x036\x036\x036\x037\x037\x037\x037\x038\x038\x038\x038\x039\x03" +
		"9\x039\x039\x03:\x03:\x03:\x03:\x03;\x03;\x06;\u016E\n;\r;\x0E;\u016F" +
		"\x03<\x03<\x03=\x03=\x03>\x03>\x03>\x03>\x07>\u017A\n>\f>\x0E>\u017D\v" +
		">\x03>\x03>\x03>\x03>\x07>\u0183\n>\f>\x0E>\u0186\v>\x03>\x03>\x03>\x07" +
		">\u018B\n>\f>\x0E>\u018E\v>\x03>\x05>\u0191\n>\x05\u017B\u0184\u018C\x02" +
		"\x02?\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b" +
		"\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02" +
		"\x0F\x1D\x02\x10\x1F\x02\x11!\x02\x12#\x02\x13%\x02\x14\'\x02\x15)\x02" +
		"\x16+\x02\x17-\x02\x18/\x02\x191\x02\x1A3\x02\x1B5\x02\x1C7\x02\x1D9\x02" +
		"\x1E;\x02\x1F=\x02 ?\x02!A\x02\"C\x02#E\x02$G\x02%I\x02&K\x02\'M\x02(" +
		"O\x02)Q\x02*S\x02+U\x02,W\x02-Y\x02.[\x02/]\x020_\x021a\x022c\x023e\x02" +
		"4g\x025i\x026k\x027m\x028o\x029q\x02:s\x02;u\x02<w\x02=y\x02>{\x02\x02" +
		"\x03\x02\x1D\x04\x02..00\x03\x022;\x04\x02OOoo\x04\x02QQqq\x04\x02FFf" +
		"f\x04\x02KKkk\x04\x02XXxx\x04\x02RRrr\x04\x02GGgg\x04\x02UUuu\x04\x02" +
		"SSss\x04\x02TTtt\x04\x02VVvv\x04\x02HHhh\x04\x02NNnn\x04\x02EEee\x04\x02" +
		"CCcc\x04\x02DDdd\x04\x02WWww\x04\x02PPpp\x04\x02MMmm\x04\x02JJjj\x03\x02" +
		"44\x04\x02ZZzz\x04\x02IIii\x05\x02\v\f\x0F\x0F\"\"\v\x022;C\\c|\xC6\xC6" +
		"\xD8\xD8\xDE\xDE\xE6\xE6\xF8\xF8\xFE\xFE\x02\u019C\x02\x03\x03\x02\x02" +
		"\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02" +
		"\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02" +
		"\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02" +
		"\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02" +
		"\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02\x02\x02\x02!\x03\x02\x02\x02" +
		"\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02\x02\'\x03\x02\x02\x02\x02)" +
		"\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02-\x03\x02\x02\x02\x02/\x03\x02" +
		"\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02\x02\x02\x025\x03\x02\x02\x02" +
		"\x027\x03\x02\x02\x02\x029\x03\x02\x02\x02\x02;\x03\x02\x02\x02\x02=\x03" +
		"\x02\x02\x02\x02?\x03\x02\x02\x02\x02A\x03\x02\x02\x02\x02C\x03\x02\x02" +
		"\x02\x02E\x03\x02\x02\x02\x02G\x03\x02\x02\x02\x02I\x03\x02\x02\x02\x02" +
		"K\x03\x02\x02\x02\x02M\x03\x02\x02\x02\x02O\x03\x02\x02\x02\x02Q\x03\x02" +
		"\x02\x02\x02S\x03\x02\x02\x02\x02U\x03\x02\x02\x02\x02W\x03\x02\x02\x02" +
		"\x02Y\x03\x02\x02\x02\x02[\x03\x02\x02\x02\x02]\x03\x02\x02\x02\x02_\x03" +
		"\x02\x02\x02\x02a\x03\x02\x02\x02\x02c\x03\x02\x02\x02\x02e\x03\x02\x02" +
		"\x02\x02g\x03\x02\x02\x02\x02i\x03\x02\x02\x02\x02k\x03\x02\x02\x02\x02" +
		"m\x03\x02\x02\x02\x02o\x03\x02\x02\x02\x02q\x03\x02\x02\x02\x02s\x03\x02" +
		"\x02\x02\x02u\x03\x02\x02\x02\x02w\x03\x02\x02\x02\x02y\x03\x02\x02\x02" +
		"\x03}\x03\x02\x02\x02\x05\x7F\x03\x02\x02\x02\x07\x81\x03\x02\x02\x02" +
		"\t\x83\x03\x02\x02\x02\v\x85\x03\x02\x02\x02\r\x88\x03\x02\x02\x02\x0F" +
		"\x8A\x03\x02\x02\x02\x11\x8C\x03\x02\x02\x02\x13\x8F\x03\x02\x02\x02\x15" +
		"\x91\x03\x02\x02\x02\x17\x93\x03\x02\x02\x02\x19\x95\x03\x02\x02\x02\x1B" +
		"\x97\x03\x02\x02\x02\x1D\x9A\x03\x02\x02\x02\x1F\xA3\x03\x02\x02\x02!" +
		"\xB7\x03\x02\x02\x02#\xB9\x03\x02\x02\x02%\xBB\x03\x02\x02\x02\'\xBF\x03" +
		"\x02\x02\x02)\xC3\x03\x02\x02\x02+\xC5\x03\x02\x02\x02-\xC7\x03\x02\x02" +
		"\x02/\xC9\x03\x02\x02\x021\xCB\x03\x02\x02\x023\xCE\x03\x02\x02\x025\xD1" +
		"\x03\x02\x02\x027\xD4\x03\x02\x02\x029\xD6\x03\x02\x02\x02;\xDB\x03\x02" +
		"\x02\x02=\xDF\x03\x02\x02\x02?\xE5\x03\x02\x02\x02A\xEA\x03\x02\x02\x02" +
		"C\xEE\x03\x02\x02\x02E\xF5\x03\x02\x02\x02G\xFB\x03\x02\x02\x02I\u0101" +
		"\x03\x02\x02\x02K\u0105\x03\x02\x02\x02M\u0109\x03\x02\x02\x02O\u010D" +
		"\x03\x02\x02\x02Q\u0111\x03\x02\x02\x02S\u0116\x03\x02\x02\x02U\u011B" +
		"\x03\x02\x02\x02W\u0120\x03\x02\x02\x02Y\u0127\x03\x02\x02\x02[\u012E" +
		"\x03\x02\x02\x02]\u0135\x03\x02\x02\x02_\u013D\x03\x02\x02\x02a\u0144" +
		"\x03\x02\x02\x02c\u0148\x03\x02\x02\x02e\u014B\x03\x02\x02\x02g\u014F" +
		"\x03\x02\x02\x02i\u0153\x03\x02\x02\x02k\u0157\x03\x02\x02\x02m\u015B" +
		"\x03\x02\x02\x02o\u015F\x03\x02\x02\x02q\u0163\x03\x02\x02\x02s\u0167" +
		"\x03\x02\x02\x02u\u016B\x03\x02\x02\x02w\u0171\x03\x02\x02\x02y\u0173" +
		"\x03\x02\x02\x02{\u0190\x03\x02\x02\x02}~\x07?\x02\x02~\x04\x03\x02\x02" +
		"\x02\x7F\x80\x07*\x02\x02\x80\x06\x03\x02\x02\x02\x81\x82\x07+\x02\x02" +
		"\x82\b\x03\x02\x02\x02\x83\x84\x07`\x02\x02\x84\n\x03\x02\x02\x02\x85" +
		"\x86\x07,\x02\x02\x86\x87\x07,\x02\x02\x87\f\x03\x02\x02\x02\x88\x89\x07" +
		"\'\x02\x02\x89\x0E\x03\x02\x02\x02\x8A\x8B\x07\x80\x02\x02\x8B\x10\x03" +
		"\x02\x02\x02\x8C\x8D\x071\x02\x02\x8D\x8E\x071\x02\x02\x8E\x12\x03\x02" +
		"\x02\x02\x8F\x90\x07]\x02\x02\x90\x14\x03\x02\x02\x02\x91\x92\x07_\x02" +
		"\x02\x92\x16\x03\x02\x02\x02\x93\x94\x07}\x02\x02\x94\x18\x03\x02\x02" +
		"\x02\x95\x96\x07\x7F\x02\x02\x96\x1A\x03\x02\x02\x02\x97\x98\x07*\x02" +
		"\x02\x98\x99\x07+\x02\x02\x99\x1C\x03\x02\x02\x02\x9A\x9B\x070\x02\x02" +
		"\x9B\x9C\x070\x02\x02\x9C\x1E\x03\x02\x02\x02\x9D\xA4\x05!\x11\x02\x9E" +
		"\xA0\x05#\x12\x02\x9F\x9E\x03\x02\x02\x02\xA0\xA1\x03\x02\x02\x02\xA1" +
		"\x9F\x03\x02\x02\x02\xA1\xA2\x03\x02\x02\x02\xA2\xA4\x03\x02\x02\x02\xA3" +
		"\x9D\x03\x02\x02\x02\xA3\x9F\x03\x02\x02\x02\xA4 \x03\x02\x02\x02\xA5" +
		"\xA7\x05#\x12\x02\xA6\xA5\x03\x02\x02\x02\xA7\xA8\x03\x02\x02\x02\xA8" +
		"\xA6\x03\x02\x02\x02\xA8\xA9\x03\x02\x02\x02\xA9\xAA\x03\x02\x02\x02\xAA" +
		"\xAE\t\x02\x02\x02\xAB\xAD\x05#\x12\x02\xAC\xAB\x03\x02\x02\x02\xAD\xB0" +
		"\x03\x02\x02\x02\xAE\xAC\x03\x02\x02\x02\xAE\xAF\x03\x02\x02\x02\xAF\xB8" +
		"\x03\x02\x02\x02\xB0\xAE\x03\x02\x02\x02\xB1\xB3\t\x02\x02\x02\xB2\xB4" +
		"\x05#\x12\x02\xB3\xB2\x03\x02\x02\x02\xB4\xB5\x03\x02\x02\x02\xB5\xB3" +
		"\x03\x02\x02\x02\xB5\xB6\x03\x02\x02\x02\xB6\xB8\x03\x02\x02\x02\xB7\xA6" +
		"\x03\x02\x02\x02\xB7\xB1\x03\x02\x02\x02\xB8\"\x03\x02\x02\x02\xB9\xBA" +
		"\t\x03\x02\x02\xBA$\x03\x02\x02\x02\xBB\xBC\t\x04\x02\x02\xBC\xBD\t\x05" +
		"\x02\x02\xBD\xBE\t\x06\x02\x02\xBE&\x03\x02\x02\x02\xBF\xC0\t\x06\x02" +
		"\x02\xC0\xC1\t\x07\x02\x02\xC1\xC2\t\b\x02\x02\xC2(\x03\x02\x02\x02\xC3" +
		"\xC4\x07,\x02\x02\xC4*\x03\x02\x02\x02\xC5\xC6\x071\x02\x02\xC6,\x03\x02" +
		"\x02\x02\xC7\xC8\x07-\x02\x02\xC8.\x03\x02\x02\x02\xC9\xCA\x07/\x02\x02" +
		"\xCA0\x03\x02\x02\x02\xCB\xCC\t\t\x02\x02\xCC\xCD\t\x07\x02\x02\xCD2\x03" +
		"\x02\x02\x02\xCE\xCF\t\n\x02\x02\xCF\xD0\x07-\x02\x02\xD04\x03\x02\x02" +
		"\x02\xD1\xD2\t\n\x02\x02\xD2\xD3\x07/\x02\x02\xD36\x03\x02\x02\x02\xD4" +
		"\xD5\t\n\x02\x02\xD58\x03\x02\x02\x02\xD6\xD7\t\v\x02\x02\xD7\xD8\t\f" +
		"\x02\x02\xD8\xD9\t\r\x02\x02\xD9\xDA\t\x0E\x02\x02\xDA:\x03\x02\x02\x02" +
		"\xDB\xDC\t\v\x02\x02\xDC\xDD\t\f\x02\x02\xDD\xDE\t\r\x02\x02\xDE<\x03" +
		"\x02\x02\x02\xDF\xE0\t\x0F\x02\x02\xE0\xE1\t\x10\x02\x02\xE1\xE2\t\x05" +
		"\x02\x02\xE2\xE3\t\x05\x02\x02\xE3\xE4\t\r\x02\x02\xE4>\x03\x02\x02\x02" +
		"\xE5\xE6\t\x11\x02\x02\xE6\xE7\t\n\x02\x02\xE7\xE8\t\x07\x02\x02\xE8\xE9" +
		"\t\x10\x02\x02\xE9@\x03\x02\x02\x02\xEA\xEB\t\x12\x02\x02\xEB\xEC\t\x13" +
		"\x02\x02\xEC\xED\t\v\x02\x02\xEDB\x03\x02\x02\x02\xEE\xEF\t\r\x02\x02" +
		"\xEF\xF0\t\x05\x02\x02\xF0\xF1\t\x14\x02\x02\xF1\xF2\t\x15\x02\x02\xF2" +
		"\xF3\t\x06\x02\x02\xF3\xF4\t\x16\x02\x02\xF4D\x03\x02\x02\x02\xF5\xF6" +
		"\t\r\x02\x02\xF6\xF7\t\x05\x02\x02\xF7\xF8\t\x14\x02\x02\xF8\xF9\t\x15" +
		"\x02\x02\xF9\xFA\t\x06\x02\x02\xFAF\x03\x02\x02\x02\xFB\xFC\t\x0E\x02" +
		"\x02\xFC\xFD\t\r\x02\x02\xFD\xFE\t\x14\x02\x02\xFE\xFF\t\x15\x02\x02\xFF" +
		"\u0100\t\x11\x02\x02\u0100H\x03\x02\x02\x02\u0101\u0102\t\v\x02\x02\u0102" +
		"\u0103\t\x07\x02\x02\u0103\u0104\t\x15\x02\x02\u0104J\x03\x02\x02\x02" +
		"\u0105\u0106\t\x11\x02\x02\u0106\u0107\t\x05\x02\x02\u0107\u0108\t\v\x02" +
		"\x02\u0108L\x03\x02\x02\x02\u0109\u010A\t\x0E\x02\x02\u010A\u010B\t\x12" +
		"\x02\x02\u010B\u010C\t\x15\x02\x02\u010CN\x03\x02\x02\x02\u010D\u010E" +
		"\t\x11\x02\x02\u010E\u010F\t\x05\x02\x02\u010F\u0110\t\x0E\x02\x02\u0110" +
		"P\x03\x02\x02\x02\u0111\u0112\t\v\x02\x02\u0112\u0113\t\x07\x02\x02\u0113" +
		"\u0114\t\x15\x02\x02\u0114\u0115\t\x17\x02\x02\u0115R\x03\x02\x02\x02" +
		"\u0116\u0117\t\x11\x02\x02\u0117\u0118\t\x05\x02\x02\u0118\u0119\t\v\x02" +
		"\x02\u0119\u011A\t\x17\x02\x02\u011AT\x03\x02\x02\x02\u011B\u011C\t\x0E" +
		"\x02\x02\u011C\u011D\t\x12\x02\x02\u011D\u011E\t\x15\x02\x02\u011E\u011F" +
		"\t\x17\x02\x02\u011FV\x03\x02\x02\x02\u0120\u0121\t\x12\x02\x02\u0121" +
		"\u0122\t\r\x02\x02\u0122\u0123\t\x11\x02\x02\u0123\u0124\t\v\x02\x02\u0124" +
		"\u0125\t\x07\x02\x02\u0125\u0126\t\x15\x02\x02\u0126X\x03\x02\x02\x02" +
		"\u0127\u0128\t\x12\x02\x02\u0128\u0129\t\r\x02\x02\u0129\u012A\t\x11\x02" +
		"\x02\u012A\u012B\t\x11\x02\x02\u012B\u012C\t\x05\x02\x02\u012C\u012D\t" +
		"\v\x02\x02\u012DZ\x03\x02\x02\x02\u012E\u012F\t\x12\x02\x02\u012F\u0130" +
		"\t\r\x02\x02\u0130\u0131\t\x11\x02\x02\u0131\u0132\t\x0E\x02\x02\u0132" +
		"\u0133\t\x12\x02\x02\u0133\u0134\t\x15\x02\x02\u0134\\\x03\x02\x02\x02" +
		"\u0135\u0136\t\x12\x02\x02\u0136\u0137\t\r\x02\x02\u0137\u0138\t\x11\x02" +
		"\x02\u0138\u0139\t\x0E\x02\x02\u0139\u013A\t\x12\x02\x02\u013A\u013B\t" +
		"\x15\x02\x02\u013B\u013C\t\x18\x02\x02\u013C^\x03\x02\x02\x02\u013D\u013E" +
		"\t\x12\x02\x02\u013E\u013F\t\r\x02\x02\u013F\u0140\t\x11\x02\x02\u0140" +
		"\u0141\t\x11\x02\x02\u0141\u0142\t\x05\x02\x02\u0142\u0143\t\x0E\x02\x02" +
		"\u0143`\x03\x02\x02\x02\u0144\u0145\t\n\x02\x02\u0145\u0146\t\x19\x02" +
		"\x02\u0146\u0147\t\t\x02\x02\u0147b\x03\x02\x02\x02\u0148\u0149\t\x10" +
		"\x02\x02\u0149\u014A\t\x15\x02\x02\u014Ad\x03\x02\x02\x02\u014B\u014C" +
		"\t\n\x02\x02\u014C\u014D\t\n\x02\x02\u014D\u014E\t\x19\x02\x02\u014Ef" +
		"\x03\x02\x02\x02\u014F\u0150\t\x10\x02\x02\u0150\u0151\t\x05\x02\x02\u0151" +
		"\u0152\t\x1A\x02\x02\u0152h\x03\x02\x02\x02\u0153\u0154\t\r\x02\x02\u0154" +
		"\u0155\t\x12\x02\x02\u0155\u0156\t\x06\x02\x02\u0156j\x03\x02\x02\x02" +
		"\u0157\u0158\t\x06\x02\x02\u0158\u0159\t\n\x02\x02\u0159\u015A\t\x1A\x02" +
		"\x02\u015Al\x03\x02\x02\x02\u015B\u015C\t\x04\x02\x02\u015C\u015D\t\x07" +
		"\x02\x02\u015D\u015E\t\x15\x02\x02\u015En\x03\x02\x02\x02\u015F\u0160" +
		"\t\x04\x02\x02\u0160\u0161\t\x12\x02\x02\u0161\u0162\t\x19\x02\x02\u0162" +
		"p\x03\x02\x02\x02\u0163\u0164\t\x1B\x02\x02\u0164\u0165\x03\x02\x02\x02" +
		"\u0165\u0166\b9\x02\x02\u0166r\x03\x02\x02\x02\u0167\u0168\x05{>\x02\u0168" +
		"\u0169\x03\x02\x02\x02\u0169\u016A\b:\x02\x02\u016At\x03\x02\x02\x02\u016B" +
		"\u016D\x07%\x02\x02\u016C\u016E\t\x1C\x02\x02\u016D\u016C\x03\x02\x02" +
		"\x02\u016E\u016F\x03\x02\x02\x02\u016F\u016D\x03\x02\x02\x02\u016F\u0170" +
		"\x03\x02\x02\x02\u0170v\x03\x02\x02\x02\u0171\u0172\x07=\x02\x02\u0172" +
		"x\x03\x02\x02\x02\u0173\u0174\v\x02\x02\x02\u0174z\x03\x02\x02\x02\u0175" +
		"\u0176\x071\x02\x02\u0176\u0177\x07,\x02\x02\u0177\u017B\x03\x02\x02\x02" +
		"\u0178\u017A\v\x02\x02\x02\u0179\u0178\x03\x02\x02\x02\u017A\u017D\x03" +
		"\x02\x02\x02\u017B\u017C\x03\x02\x02\x02\u017B\u0179\x03\x02\x02\x02\u017C" +
		"\u017E\x03\x02\x02\x02\u017D\u017B\x03\x02\x02\x02\u017E\u017F\x07,\x02" +
		"\x02\u017F\u0191\x071\x02\x02\u0180\u0184\x07)\x02\x02\u0181\u0183\v\x02" +
		"\x02\x02\u0182\u0181\x03\x02\x02\x02\u0183\u0186\x03\x02\x02\x02\u0184" +
		"\u0185\x03\x02\x02\x02\u0184\u0182\x03\x02\x02\x02\u0185\u0187\x03\x02" +
		"\x02\x02\u0186\u0184\x03\x02\x02\x02\u0187\u0191\x07)\x02\x02\u0188\u018C" +
		"\x07$\x02\x02\u0189\u018B\v\x02\x02\x02\u018A\u0189\x03\x02\x02\x02\u018B" +
		"\u018E\x03\x02\x02\x02\u018C\u018D\x03\x02\x02\x02\u018C\u018A\x03\x02" +
		"\x02\x02\u018D\u018F\x03\x02\x02\x02\u018E\u018C\x03\x02\x02\x02\u018F" +
		"\u0191\x07$\x02\x02\u0190\u0175\x03\x02\x02\x02\u0190\u0180\x03\x02\x02" +
		"\x02\u0190\u0188\x03\x02\x02\x02\u0191|\x03\x02\x02\x02\x0F\x02\xA1\xA3" +
		"\xA8\xAE\xB5\xB7\u016D\u016F\u017B\u0184\u018C\u0190\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!CalculatorLexer.__ATN) {
			CalculatorLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(CalculatorLexer._serializedATN));
		}

		return CalculatorLexer.__ATN;
	}

}

